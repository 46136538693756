<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1562.76 348.45"
  >
    <defs>
      <component :is="'style'">
        .cls-1 {
        fill: #4c00ff;
        }

        .cls-1, .cls-2 {
        stroke-width: 0px;
        }

        .cls-2 {
        fill: #ed166e;
        }
      </component>
    </defs>
    <g>
      <path
        class="cls-2"
        d="m1269.88,229.81c-27.1,0-49.34,8.95-66.71,26.84-4.79,4.79-8.9,10.33-12.37,16.34h158.7c-3.49-5.87-7.65-11.32-12.51-16.34-17.89-17.9-40.26-26.84-67.1-26.84Z"
      />
      <path
        class="cls-2"
        d="m1336.99,213.48c-17.89-17.9-40.26-26.84-67.1-26.84s-49.34,8.95-66.71,26.84c-4.79,4.79-8.9,10.33-12.37,16.34h158.7c-3.49-5.87-7.65-11.32-12.51-16.34Z"
      />
    </g>
    <g>
      <path
        class="cls-1"
        d="m92.06,100.2c-19.87,0-36.1,6.17-48.67,18.49V28.74H0v244.25h43.39v-116.58c10.31-10.31,22.76-15.47,37.35-15.47,12.07,0,21.38,3.39,27.92,10.19,6.54,6.79,9.81,16.48,9.81,29.05v92.81h43.39v-98.47c0-49.55-23.27-74.33-69.8-74.33Z"
      />
      <path
        class="cls-1"
        d="m608.83,100.2c-24.4,0-43.52,9.06-57.35,27.17-3.27-8.3-9.24-14.9-17.92-19.81-8.68-4.91-19.18-7.36-31.5-7.36-22.39,0-40.12,7.3-53.2,21.89v-18.86h-43.39v169.78h43.39v-113.94c7.79-12.07,19.62-18.11,35.46-18.11,9.06,0,16.03,2.64,20.94,7.92,4.9,5.28,7.36,12.45,7.36,21.51v102.62h43.39v-99.98c0-1-.06-3.21-.19-6.6-.13-3.4-.19-5.34-.19-5.85,6.79-13.08,18.49-19.62,35.09-19.62,19.37,0,29.05,10.82,29.05,32.45v99.6h43.39v-110.93c0-41.24-18.11-61.87-54.33-61.87Z"
      />
      <path
        class="cls-1"
        d="m839.02,121.33c-15.6-14.84-34.59-22.26-56.97-22.26-27.17,0-48.92,8.56-65.27,25.66-16.35,17.11-24.52,38.23-24.52,63.38,0,26.92,8.3,48.42,24.9,64.52,17.1,16.35,39.24,24.52,66.4,24.52s48.04-7.04,64.89-21.13l-12.45-30.56c-13.08,10.32-29.05,15.47-47.92,15.47-13.83,0-25.34-3.52-34.52-10.56-9.18-7.04-14.65-16.73-16.41-29.05h122.62c1.76-5.53,2.64-13.33,2.64-23.39,0-22.88-7.8-41.75-23.39-56.59Zm-20,50.93h-83.38c5.03-24.65,19.87-36.97,44.52-36.97,11.82,0,21.32,3.46,28.49,10.37,7.17,6.92,10.62,15.79,10.37,26.6Z"
      />
      <path
        class="cls-1"
        d="m934.9,211.55V28.74h-43.39v198.96c0,26.31,21.33,47.64,47.64,47.64h13.43v-42.14c-9.77,0-17.68-11.89-17.68-21.65Z"
      />
      <path
        class="cls-1"
        d="m1541.63,123.21c-13.84-16.1-32.45-24.15-55.84-24.15-10.32,0-20.25,1.89-29.81,5.66-9.56,3.77-17.11,8.68-22.64,14.72v-16.22h-43.39v245.23h43.39v-79.61c9.3,5.53,22.51,8.3,39.61,8.3,26.66,0,48.29-8.67,64.89-26.03,16.6-17.35,24.9-39.24,24.9-65.65s-7.04-45.9-21.13-62.25Zm-37.92,99.61c-8.68,9.31-20.19,13.96-34.52,13.96-13.58,0-25.53-3.27-35.84-9.81v-68.67c10.81-12.32,23.77-18.48,38.86-18.48,13.83,0,24.71,4.28,32.64,12.83,7.92,8.56,11.88,19.87,11.88,33.96,0,14.85-4.34,26.92-13.01,36.22Z"
      />
      <path
        class="cls-1"
        d="m1071.39,98.8c-28,0-50.45,8.46-67.35,25.35-16.91,16.65-25.36,37.97-25.36,63.95s8.45,47.43,25.36,64.33c16.9,16.65,39.35,24.97,67.35,24.97s49.82-8.32,66.98-24.97c17.15-17.15,25.73-38.6,25.73-64.33s-8.58-47.3-25.73-63.95c-16.9-16.9-39.23-25.35-66.98-25.35Zm33.49,124.49c-8.7,9.08-19.87,13.62-33.49,13.62s-24.41-4.6-33.11-13.81c-8.7-9.21-13.06-20.87-13.06-35s4.41-25.73,13.25-34.82c8.83-9.08,19.8-13.62,32.92-13.62s24.46,4.54,33.3,13.62c8.83,9.08,13.24,20.69,13.24,34.82s-4.35,26.11-13.05,35.19Z"
      />
      <path
        class="cls-1"
        d="m283.66,98.8c-28,0-50.45,8.46-67.35,25.35-16.91,16.65-25.36,37.97-25.36,63.95s8.45,47.43,25.36,64.33c16.9,16.65,39.35,24.97,67.35,24.97s49.82-8.32,66.98-24.97c17.15-17.15,25.73-38.6,25.73-64.33s-8.58-47.3-25.73-63.95c-16.9-16.9-39.23-25.35-66.98-25.35Zm33.49,124.49c-8.7,9.08-19.87,13.62-33.49,13.62s-24.41-4.6-33.11-13.81c-8.7-9.21-13.06-20.87-13.06-35s4.41-25.73,13.25-34.82c8.83-9.08,19.8-13.62,32.92-13.62s24.46,4.54,33.3,13.62c8.83,9.08,13.24,20.69,13.24,34.82s-4.35,26.11-13.05,35.19Z"
      />
      <path
        class="cls-1"
        d="m1270.15,0c-28,0-50.45,8.46-67.35,25.36-16.91,16.65-25.36,37.97-25.36,63.95s8.45,47.43,25.36,64.33c16.9,16.65,39.35,24.97,67.35,24.97s49.82-8.32,66.98-24.97c17.15-17.15,25.73-38.6,25.73-64.33s-8.58-47.3-25.73-63.95c-16.9-16.9-39.23-25.36-66.98-25.36Zm33.49,124.49c-8.7,9.08-19.87,13.62-33.49,13.62s-24.41-4.6-33.11-13.81c-8.7-9.21-13.06-20.87-13.06-35s4.41-25.73,13.25-34.82c8.83-9.08,19.8-13.62,32.92-13.62s24.46,4.54,33.3,13.62c8.83,9.08,13.24,20.69,13.24,34.82s-4.35,26.11-13.05,35.19Z"
      />
    </g>
  </svg>
</template>
